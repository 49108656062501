<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CriteriaOfClass from "./component/CriteriaOfClass.vue";
import { GET_ANALYST, GET_LIST_CLASS_BY_TEACHER } from "@/core/services/store/supportClass/supportClass.module";
import { ClassOfUser, statusClass } from "@/views/pages/home/model/ClassOfUser";
import Paginate from "vuejs-paginate";

export default {
  name: "home",
  components: { CriteriaOfClass, Paginate },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê tình hình lớp học" }]);
    this.getListClassByTeacher();
    this.getListClass();
  },
  data() {
    return {
      listClassOfUser: [],
      statusClassOption: statusClass,
      page: 1,
      lastPage: 0,
      total: 0,
      status: 2,
      class_id: '',
      listClass: [],
      listClassByStatus: [],
      loading: true,
    }
  },
  watch: {
  },
  methods: {
    changeClass() {
      this.page = 1;
    },
    changeStatus() {
      this.page = 1;
      this.queryListClassByStatus(this.status)
    },
    convertObjectToArray(object) {
      const arrayKeys = ['diligence', 'homeWork', 'errorLesson', 'meetRequirement', 'test']
      return arrayKeys.map(key => {
        if (object.hasOwnProperty(key)) {
          return object[key]
        }
        return 'null';
      })
    },
    async getListClassByTeacher() {
      await this.$store.dispatch(GET_LIST_CLASS_BY_TEACHER).then(response => {
        try {
          const data = response.data.data
          data.length > 0 ? this.listClass = data : [];
          this.queryListClassByStatus(this.status);
        } catch (error) {
          console.log(error)
        }
      })
    },
    async getListClass() {
      this.loading = true;
      const payload = {
        status: this.status,
        class_id: this.class_id,
        page: this.page,
      }
      const response = await this.$store.dispatch(GET_ANALYST, payload);
      const data = response.data.data ?? [];
      this.lastPage = response.data.last_page
      this.total = response.data.total
      this.loading = false;
      this.listClassOfUser = data.map(item => {
        return new ClassOfUser(
          item?.class_id,
          item?.class_name,
          this.convertObjectToArray(item?.data_report),
        )
      });
    },
    queryListClassByStatus(status) {
      this.listClassByStatus = this.listClass.filter(item => item?.status_code == status);
      this.class_id = '';
    },
    clickCallback(obj) {
      this.page = obj;
      this.getListClass();
    },
  }
}
</script>

<template>
  <div
    style="display: flex; flex-direction: column; justify-content: center; width: 100%; background: white; padding-bottom: 10px">
    <div class="w-100 p-4">
      <div class="d-flex container-support" style="align-items: end; gap: 20px">
        <div class="input-class">
          <h1 style="font-size: 15px">Trạng thái lớp</h1>
          <el-select @change="changeStatus" placeholder="Chọn trạng thái" class="w-100" v-model="status"
            :clearable="true">
            <el-option v-for="item in statusClassOption" :key="status + item.value" :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="layout">
          <h1 style="font-size: 15px">Lớp học</h1>
          <el-select placeholder="Chọn lớp" class="w-100" v-model="class_id" @change="changeClass" :clearable="true">
            <el-option v-for="item in listClassByStatus" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <el-button :disabled="loading" style="display: flex; align-content: end; height: 36px; align-items: center" type="primary"
          @click="getListClass"><i class="mr-2" v-bind:class="[loading ? 'el-icon-loading' : 'fa fa-search']"></i>Tìm kiếm</el-button>
      </div>
      <div v-if="loading" style="display: flex; justify-content: center; width: 100%; margin-top: 20px">
        <i class="el-icon-loading"></i>
      </div>
      <div v-else>
        <div class="mt-6" v-for="(classUser, indexClass) in listClassOfUser" :key="indexClass">
          <h1 style="font-size: 15px; color: black">Lớp {{ classUser.nameClass }}</h1>
          <div class="d-flex flex-wrap">
            <CriteriaOfClass v-for="(criteria, indexCriteria) in classUser.ListCriteria"
              :key="indexClass + indexCriteria.toString()" style="margin-left: 10px" :criteria="criteria"
              :index="indexClass + indexCriteria.toString()" />
          </div>
        </div>
        <div style="display: flex; justify-content: center; width: 100%; margin-top: 20px; height: 100%">
          <paginate class="border" v-model="page" :page-count="lastPage" :page-range="3" :margin-pages="2"
            :click-handler="clickCallback" :prev-text="'Trước'" :next-text="'Sau'"
            :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
            :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
            :next-class="'page-link'" :page-class="'page-item'" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-support {
  width: 50%;
  flex-wrap: wrap;
}

@media (max-width: 1024.5px) {
  .container-support {
    width: 100%;
  }
}

.input-class {
  width: 50%;
}

@media (max-width: 425.5px) {
  .input-class {
    width: 100%;
  }
}
</style>